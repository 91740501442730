import './App.css';
import LandingPage from './LandingPage';

function App() {
  return (
    <div className='bg-font-mono cursor-none '>
      <LandingPage />
    </div>
  );
}

export default App;
